import _ from 'lodash'
import CoreApi from '../../core-api'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../../../constants/roles'
import { RegistrationFieldPreset, FormPlugin } from '@wix/forms-common'
import translations from '../../../../utils/translations'
import { CATEGORIES } from '../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { ExtendApi, ExtendApiCreator } from '../extend-api'
import { FOOTER_CTA_CLICKED } from '../event-types'
import { ACTION_TYPE } from '../action-types'
import { getFieldRenderConfigFields } from '../../preset/fields/field-types-data'
import { fieldsStore, fieldsTypes } from '../../preset/fields/fields-store'

// TODO: Move this to single point of configuration
const CrucialFieldPreset = {
  LOGIN_EMAIL: RegistrationFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL,
  PASSWORD: RegistrationFieldPreset.REGISTRATION_FORM_PASSWORD,
  LINK_TO_LOGIN: RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN,
}

export const REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES: FieldPreset[] = _.values(
  CrucialFieldPreset,
)

class RegistrationFormApi extends ExtendApiCreator {
  private coreApi: CoreApi

  constructor(coreApi: CoreApi) {
    super()
    this.coreApi = coreApi
  }

  get events(): { [event: string]: (payload: any) => any } {
    return {
      [FOOTER_CTA_CLICKED]: (_eventPayload) => {
        this.coreApi.managePanels.openMemberSignupPagePanel()
      },
    }
  }

  getCrucialElements = async (
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
  ) => {
    const { controllerRef } = componentConnection

    const missingFields = await Promise.all([
      this.coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE),
      this.coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON), // TODO: Combine to single call
      this.coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.LOGIN_EMAIL),
      this.coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.PASSWORD),
      this.coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.LINK_TO_LOGIN),
    ])

    return _.filter(missingFields)
  }

  getNewFields = () => {
    return {
      add: [
        ..._.map(fieldsTypes(fieldsStore.registrationFields), (type: FieldPreset) => ({
          value: type,
          name: translations.t(`fieldTypes.${type}`),
          category: CATEGORIES.registration,
        })),
      ],
      remove: _.reduce(
        fieldsTypes(fieldsStore.recommendedFields),
        (acc, fieldType: FieldPreset) => {
          const fieldRenderConfig = getFieldRenderConfigFields(
            [FormPlugin.REGISTRATION_FORM],
            fieldType,
          )
          const shouldRemoveField = fieldRenderConfig.addFieldPanelData.category === CATEGORIES.none

          return shouldRemoveField ? [...acc, fieldType] : acc
        },
        [],
      ),
    }
  }

  getAddFieldLayoutOverrides = async () => {
    return {
      [CATEGORIES.contact]: {
        biTabKey: 'member-fields',
        verticalTab: translations.t('addField.verticalTabs.member'),
        sectionIntro: {
          key: 'addField.memberSection.text',
          links: {
            memberLink: {
              type: ACTION_TYPE.OPEN_BIZ_PANEL,
              entry: '/members-account',
              text: translations.t('addField.membersAccountLink'),
            },
            learnLink: {
              type: ACTION_TYPE.KB_LINK,
              entry: '/wix-forms-saving-your-form-data-to-your-contacts',
              text: translations.t('addField.membersLearnMore'),
            },
          },
        },
      },
    }
  }

  getExtendApi(): ExtendApi {
    return {
      onEvent: this.createOnEvent(),
      'settings.getCrucialElements': this.getCrucialElements,
      'fields.getNewFields': this.getNewFields,
      'fields.getAddFieldLayoutOverrides': this.getAddFieldLayoutOverrides,
      appConfig: async (): Promise<AppConfig> => {
        const baseUrl = await this.coreApi.getBusinessManagerUrl()
        return {
          features: {
            notifications: false,
            collection: false,
          },
          mainCTA: {
            description: translations.t('formSettings.mainTab.registrationForm.mainActionContent'),
            actions: [
              {
                type: ACTION_TYPE.LINK,
                href: `${baseUrl}/member-permissions/members`,
                text: translations.t('formSettings.mainTab.registrationForm.mainActionButtonText'),
              },
              {
                type: ACTION_TYPE.OPEN_BIZ_PANEL,
                entry: '/members-account',
                text: translations.t(
                  'formSettings.mainTab.registrationForm.secondaryActionButtonText',
                ),
                secondary: true,
              },
            ],
          },
          secondaryCTA: {
            cta: {
              type: ACTION_TYPE.CUSTOM,
              text: translations.t('formSettings.mainTab.footer.registrationForm.link'),
              href: null,
            },
          },
          content: {
            formNameInfo: translations.t('formSettings.settingsTab.registrationForm.info'),
            onSubmitMessage: translations.t('settings.errorMessage.default'),
          },
        }
      },
    }
  }
}

export default RegistrationFormApi
