import { FIELDS, COMPLEX_PHONE_ROLES, COMPLEX_ADDRESS_ROLES } from '../../../../constants/roles'

type RoleKeys = keyof typeof FIELDS
type RoleValues = typeof FIELDS[RoleKeys]

const itemListOverride = {
  panelSectionsDefinition: {
    options: 'hidden',
  },
}

const textInputFieldOverride = {
  panelSectionsDefinition: {
    inputType: 'hidden',
    value: 'hidden',
    readOnly: 'hidden',
    patternValidation: 'hidden',
  },
}

const textAreaFieldOverride = {
  panelSectionsDefinition: {
    value: 'hidden',
    readOnly: 'hidden',
  },
}

const checkboxFieldOverride = {
  panelSectionsDefinition: {
    value: 'hidden',
  },
}

const joinCommunityFieldOverride = {
  panelSectionsDefinition: {
    value: 'hidden',
    link: 'hidden',
  },
}

const datePickerOverride = {
  panelSectionsDefinition: {
    readOnly: 'hidden',
  },
}

const timePickerOverride = {
  panelSectionsDefinition: {
    readOnly: 'hidden',
  },
}

const autoCompleteAddressOverride = {
  panelSectionsDefinition: {
    readOnly: 'hidden',
  },
}

const fileUploaderOverride = {
  panelSectionsDefinition: {
    multiFilesUpload: 'hidden',
  },
}

export const excludeRolesFromHelpId = [
  FIELDS.ROLE_FIELD_SIGNATURE,
  FIELDS.ROLE_FIELD_TIME_PICKER,
  FIELDS.ROLE_FIELD_RATING,
] // this is a temp list, until help article will be fixed

export const nativePanelOverrides: () => {
  [value in RoleValues]: { panelSectionsDefinition?: object }
} = () => ({
  // TODO: This new implementation is missing the renderConfig old implementation which hides required (and other) sections, need to merge and remove the old one
  [FIELDS.ROLE_FIELD_TEXT]: textInputFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]: textInputFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]: textInputFieldOverride,
  [FIELDS.ROLE_FIELD_TEXT_AREA]: textAreaFieldOverride,
  [FIELDS.ROLE_FIELD_CHECKBOX]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_SUBSCRIBE]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_AGREE_TERMS]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PRIVACY_POLICY]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: joinCommunityFieldOverride,
  [FIELDS.ROLE_FIELD_DATE]: datePickerOverride,
  [FIELDS.ROLE_FIELD_TIME_PICKER]: timePickerOverride,
  [FIELDS.ROLE_FIELD_ITEMS_LIST]: itemListOverride,
  [FIELDS.ROLE_FIELD_CUSTOM_AMOUNT]: textInputFieldOverride,
  [FIELDS.ROLE_FIELD_AUTOCOMPLETE_ADDRESS]: autoCompleteAddressOverride,
  [FIELDS.ROLE_FIELD_FILE_UPLOAD]: fileUploaderOverride,
})

export const nativeManagePanelOverrides: () => {
  [value in RoleValues]: { panelSectionsDefinition?: object }
} = () => ({
  // TODO: This new implementation is missing the renderConfig old implementation which hides required (and other) sections, need to merge and remove the old one
  [COMPLEX_PHONE_ROLES.DROPDOWN]: {
    panelSectionsDefinition: {
      settings: 'hidden',
    },
  },
  [COMPLEX_ADDRESS_ROLES.COUNTRY]: {
    panelSectionsDefinition: {
      settings: 'hidden',
    },
  },
})
